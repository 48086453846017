// DEMO
class Glossary {
	constructor(jQueryElement) {
		this.element = jQueryElement;
		this.title = this.element.find('.glossary-title');
		this.actions = this.element.find('.glossary-actions');
		this.nav = this.element.find('.glossary-nav');
		this.lastScrollingPosition = 0;

		this.handleScrolling();
		$(window).on('scroll', this.handleScrolling.bind(this));
	}

	handleScrolling() {
		var headerHeight = $('.header').outerHeight();
		var titleHeight = this.title.outerHeight();
		var navTop = this.nav.offset().top;
		var navHeight = this.nav.outerHeight();
		var totalHeight = this.element.outerHeight();

		var scrollTop = $(window).scrollTop();
		var windowHeight = $(window).height();

		var visibleHeight = windowHeight - headerHeight - titleHeight;

		this.title.css('transform', 'translateY(' + scrollTop + 'px)');
		this.actions.css('transform', 'translateY(' + scrollTop + 'px)');

		if(navHeight > visibleHeight) {
			if(scrollTop + windowHeight > totalHeight + headerHeight) {
				// Bottom limit
				this.nav.css('transform', 'translateY(' + (totalHeight - titleHeight - navHeight) + 'px)');
			} else {
				if(scrollTop > this.lastScrollingPosition) {
					// Scrolling down
					var navBottom = navTop + navHeight;
					var bottomLimit = scrollTop + windowHeight;

					if(bottomLimit > navBottom) {
						this.nav.css('transform', 'translateY(' + (scrollTop - (navHeight - visibleHeight)) + 'px)');
					}
				} else {
					// Scrolling up
					var topLimit = scrollTop + headerHeight + titleHeight;

					if(topLimit < navTop) {
						this.nav.css('transform', 'translateY(' + scrollTop + 'px)');
					}
				}	
			}
		} else {
			var topLimit = scrollTop + headerHeight + titleHeight;
			var bottomLimit = totalHeight + headerHeight;

			if(topLimit + navHeight < bottomLimit) {
				this.nav.css('transform', 'translateY(' + scrollTop + 'px)');
			}
		}

		this.lastScrollingPosition = scrollTop;
	}
}

class Section {
	constructor(jQueryElement) {
		this.element = jQueryElement;
		this.bar = this.element.find('.section-tabs-selector');
		this.tabs = this.element.find('.section-tabs-button');
		this.panels = this.element.find('.section-content');

		this.placeSelector(this.tabs.filter('.section-tabs-button--current').parent());
		this.tabs.on('click', this.changeTab.bind(this));
	}

	changeTab(e) {
		e.preventDefault();

		var active = $(e.target).closest('.section-tabs-button');
		var item = active.parent();

		this.panels.filter('.section-content--current').removeClass('section-content--current');
		this.panels.eq(item.index() - 1).addClass('section-content--current');
		this.tabs.filter('.section-tabs-button--current').removeClass('section-tabs-button--current');
		active.addClass('section-tabs-button--current');

		this.placeSelector(item);
	}

	placeSelector(item) {
		this.bar.css({
			width: item.outerWidth() + 60,
			left: item.offset().left - item.parent().offset().left - 60
		});
	}
}

class FormStep {
	constructor(jQueryElement, id) {
		this.element = jQueryElement;
		this.id = id;
	}

	getVisibility() {
		if(this.element.is('[disabled]')) {
			return false;
		}

		var headerHeight = $('.header').outerHeight();

		var top = this.element.offset().top;
		var height = this.element.outerHeight();
		var bottom = top + height;

		var viewportTop = $(window).scrollTop() + headerHeight;
		var viewportHeight = $(window).height() - headerHeight - $('.process-footer').outerHeight();
		var viewportBottom = viewportTop + viewportHeight;

		var visibleTop = top < viewportTop ? viewportTop : top;
		var visibleBottom = bottom > viewportBottom ? viewportBottom : bottom;

		return (visibleBottom - visibleTop) / height;
	}
}

var steps = [];

$(function(e){
	// $('.process-section, .process-recap').each(function(index) {
	// 	steps.push(new FormStep($(this), index));
	// });

	$('.glossary').each(function(index){
		new Glossary($(this));
	});

	$('.section-tabs').each(function(index){
		new Section($(this).parents('.section'));
	});

	$('.chat').scrollTop(999999999);
});

$(window).on('scroll', function(e){
	// var mostVisible = 0;
	// var active = 0;

	// for(var i = 0; i < steps.length; i++) {
	// 	var visibility = steps[i].getVisibility();
		
	// 	if(visibility > mostVisible) {
	// 		mostVisible = visibility;
	// 		active = i;
	// 	}
	// }
	
// 	var activeStep = $('.process-nav-item').eq(active);
// 	if(!activeStep.hasClass('process-nav-item--selected')) {
// 		$('.process-nav-item--selected').removeClass('process-nav-item--selected');
// 		activeStep.addClass('process-nav-item--selected');

// 		$('.process-steps-current-number').html(active + 1);
		
// 		var title = $('.process-section').eq(active).find('.process-section-title');
// 		$('.process-steps-title').html(title.length ? title.html() : 'Recapitulatif');

// 		var prevButton = $('.process-controls .button:first-child');
// 		var nextButton = $('.process-controls .button:last-child');

// 		prevButton.prop('disabled', active == 0);

// 		if(active == steps.length - 1) {
// 			nextButton.find('.button-label').html('Enregistrer');
// 			nextButton.find('.button-icon').removeClass('icon-right').addClass('icon-check');
// 		} else {
// 			nextButton.find('.button-label').html('Suivant');
// 			nextButton.find('.button-icon').removeClass('icon-check').addClass('icon-right');
// 		}
// 	}
});

// $(document).on('click', '.process-footer .button--teal:first-child', function(e){
// 	var active = $('.process-nav-item--selected').index();
	
// 	$('html, body').animate({
// 		scrollTop: $('.process-section, .process-recap').eq(active - 1).offset().top - $('.header').outerHeight() - 40
// 	});
// });

$(document).on('click', '.process-nav-link', function(e){
	e.preventDefault();

	var href = $(this).attr('href');
	var target = $(href);
	if(target.length && target.is(':not([disabled])')) {
		$('html, body').animate({
			scrollTop: target.offset().top - $('.header').outerHeight() - 40
		});
	}
});

$(document).on('click', '.process-footer .button--teal:last-child', function(e){
	// var current = $('.process-section:not(:disabled)').last();
	// var next = current.next('[disabled]');

	// if(next.length) {
	// 	next.attr('disabled', false);

	// 	$('html, body').animate({
	// 		scrollTop: next.offset().top - $('.header').outerHeight() - 40
	// 	});
	// }
});

// Mail
$(document).on('click', '.header-menu-list:not(:first-child) .header-menu-item:nth-child(2) .header-menu-link', openMailPanel);

function openMailPanel(e) {
	e.preventDefault();

	$('.mail').addClass('mail--opened');

	$(document).on('click', closeMailPanel);
}

function closeMailPanel(e) {
	var target = e.target;

	if(!target.closest('.mail') && !target.closest('.chat')) {
		$('.mail--opened').removeClass('mail--opened');
		$(document).off('click', closeMailPanel);
	}
}

$(document).on('click', '.mail-close', function(e){
	$('.mail').removeClass('mail--opened');
	$(document).off('click', closeMailPanel);
});

// Chat
$(document).on('click', '.mail-card-link', openChatPanel);

function openChatPanel(e) {
	e.preventDefault();

	var index = $(this).find('.mail-card-icon--teal').length ? 1 : 0;

	$('.chat').eq(index).addClass('chat--opened');

	$(document).on('click', closeChatPanel);
}

function closeChatPanel(e) {
	var target = e.target;

	if(!target.closest('.chat')) {
		$('.chat--opened').removeClass('chat--opened');
		$(document).off('click', closeChatPanel);
	}
}

$(document).on('click', '.chat-close', function(e){
	$('.chat').removeClass('chat--opened');
	$(document).off('click', closeChatPanel);
});

// Notifications
$(document).on('click', '.header-menu-list:not(:first-child) .header-menu-item:first-child .header-menu-link', openNotificationsPanel);

function openNotificationsPanel(e) {
	e.preventDefault();

	$('.notifications').addClass('notifications--opened');

	$(document).on('click', closeNotificationsPanel);
}

function closeNotificationsPanel(e) {
	var target = e.target;

	if(!target.closest('.notifications')) {
		$('.notifications--opened').removeClass('notifications--opened');
		$(document).off('click', closeNotificationsPanel);
	}
}

// Table actions
$(document).on('click', '.table-actions-toggle', openTableActions);

function openTableActions(e) {
	e.preventDefault();

	$(this).parent().addClass('table-actions--opened');

	$(document).on('click', closeTableActions);
}

function closeTableActions(e) {
	var target = e.target;

	if(!target.closest('.table-actions--opened .table-actions-list')) {
		$('.table-actions--opened').removeClass('table-actions--opened');
		$(document).off('click', closeTableActions);
	}
}

// Search results actions
$(document).on('click', '.search-results-card-actions-toggle', openSearchActions);

function openSearchActions(e) {
	e.preventDefault();

	$(this).parent().addClass('search-results-card-actions--opened');

	$(document).on('click', closeSearchActions);
}

function closeSearchActions(e) {
	var target = e.target;

	if(!target.closest('.search-results-card-actions--opened .search-results-card-actions-list')) {
		$('.search-results-card-actions--opened').removeClass('search-results-card-actions--opened');
		$(document).off('click', closeSearchActions);
	}
}

// Mail actions
$(document).on('click', '.mail-card-actions-toggle', openMailActions);

function openMailActions(e) {
	e.preventDefault();

	$(this).parent().addClass('mail-card-actions--opened');

	$(document).on('click', closeMailActions);
}

function closeMailActions(e) {
	var target = e.target;

	if(!target.closest('.mail-card-actions--opened .mail-card-actions-list')) {
		$('.mail-card-actions--opened').removeClass('mail-card-actions--opened');
		$(document).off('click', closeMailActions);
	}
}

// Modal
$(document).on('click', '.modal-open', function(e){
	$('.modal').addClass('modal--opened');
});

$(document).on('click', '.modal-close', function(e){
	$(this).parents('.modal').removeClass('modal--opened');
});

$(document).on('click', '.details-section-header', function(e){
	$(this).parent().toggleClass('details-section--opened');
});

$(document).on('click', '.form-address-input', function(e){
	if($(this).data('checked') && $(this).data('checked') == true) {
		$(this).data('checked', false);
		$(this).prop('checked', false);
	} else {
		$('[name="' + $(this).attr('name') + '"]').data('checked', false);
		$(this).data('checked', true);
	}
});

$(document).on('click', '.mail-search-toggle', function(e){
	$(this).parent().toggleClass('mail-search--opened');
});

$(document).on('click', '.search-form-toggle', function(e){
	$(this).parent().toggleClass('search-form--closed');
});

$(document).on('click', '.form-password-toggle', function(e){
	var input = $(this).prev();
	var type = input.attr('type') == 'password' ? 'text' : 'password';
	
	input.attr('type', type);
});

$(document).on('click', '.glossary-nav-list-link', function(e){
	e.preventDefault();
	var target = $(this).attr('href');

	if($(target)) {
		$('.glossary-nav-list-link--current').removeClass('glossary-nav-list-link--current');
		$(this).addClass('glossary-nav-list-link--current');

		$('.glossary-item--current').removeClass('glossary-item--current');
		$(target).addClass('glossary-item--current');
		
		$('html, body').animate({
			scrollTop: $(target).offset().top - $('.header').outerHeight() - $('.glossary-actions').outerHeight() - 40
		});
	}
});

function statistic(path) {
	(function () {
	  const u = "https://wwwstats.brgm.fr/";
  
	  // Remove the script added before
	  const d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
	  const sSrc = s.getAttribute('src');
	  if (sSrc === u + "matomo.js") {
		s.remove();
	  }
  
	  // Set variables
	  const _paq = window._paq = window._paq || [];
	  _paq.push(["disableCookies"]);
	  _paq.push(["setCustomUrl", path]);
	  _paq.push(["trackPageView"]);
  
	  (function () {
		_paq.push(["setTrackerUrl", u + "matomo.php"]);
		_paq.push(["setSiteId", "297"]);
		const d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
		g.type = "text/javascript";
		g.async = true;
		g.src = u + "matomo.js";
		s.parentNode.insertBefore(g, s);
	  })();
	})();
  }

